.notificator {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  width: 100vw;
  height: 100vh;

  pointer-events: none;

  .toast-container {
    bottom: 10px;

    &.in-iframe {
      bottom: 70px;
    }
  }
}
