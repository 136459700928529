@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.base-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100vw;
  height: 100vh;

  .bg {
    width: 100%;
    height: 100%;

    background-color: rgb(0 0 0 / 60%);
  }

  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    max-height: 100%;
    padding: 30px 15px;

    pointer-events: none;

    .content {
      position: relative;

      display: flex;
      align-items: center;

      .event-confirm-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1000;

        transform: translate(-50%, -50%);

        pointer-events: all;
      }

      .popup {
        height: fit-content;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        background-color: color($var-color-white);
        border-top-left-radius: $main-border-radius;
        border-bottom-right-radius: $main-border-radius;
        border-bottom-left-radius: $main-border-radius;

        pointer-events: all;
      }
    }
  }

}
