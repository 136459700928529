@import "@VIEW/styles/colors";

.header {
  height: 125px;
  padding: 16px 16px 24px;

  .top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 22px;

    .legend-wrapper {
      margin-right: 30px;
    }

    .export {
      display: flex;

      button {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
  }

  .title {
    margin: 0 0 16px;

    font-weight: 700;
    font-size: 24px;
    font-style: normal;
    line-height: 29.5px;
    letter-spacing: -1.5px;
  }

  .filters {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      gap: 16px;

      .dates {
        width: 240px;
      }

      .team-lead {
        width: 210px;
      }
    }

    .roles-people {
      width: 220px;
    }


    .period {
      width: 150px;
    }
  }

  .right {
    display: flex;
    gap: 16px;

    .zoom-buttons {
      display: flex;
      gap: 8px;
    }

    .event-types {
      width: 170px;
    }

    .search {
      width: 270px;
    }
  }

}
