@import "@VIEW/styles/colors";

.location-select {
  z-index: 0;

  .search-icon {
    position: absolute;
    top: 4px;
    left: 5px;
  }

  .loading-message {
    display: flex;
    justify-content: center;
    padding: 4px;
  }

  &.is-open {
    z-index: 100;
  }

  .control-focused {
    .single-value {
      color: color($var-color-grey);
    }
  }

  .custom-location {
    position: relative;

    padding-right: 60px;

    .custom-location-option-message {
      position: absolute;
      top: 15px;
      right: 8px;

      color: color($var-color-blue-main);
      font-size: 12px;
    }

    .custom-location-option {
      color: color($var-color-grey);
      font-weight: 600;
    }

    &:hover {
      .custom-location-option {
        color: #202020;
      }
    }
  }
}
