@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.basic-textarea {
  position: relative;

  width: 100%;
  overflow: hidden;

  border-radius: $main-border-radius;

  .custom-resizer-container {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 18px;
    height: 18px;

    background-color: color($var-color-white);
    border-right: 1px solid color($var-color-grey-light);
    border-bottom: 1px solid color($var-color-grey-light);

    border-bottom-right-radius: $main-border-radius;

    pointer-events: none;

    .icon-wrapper {
      position: absolute;
      right: 2px;
      bottom: -1px;
    }
  }

  .input-label {
    font-size: 14px;
    line-height: 20px;
  }

  textarea {
    display: flex;
    width: 100%;
    padding: 16px;

    background-color: color($var-color-white);
    border: 1px solid color($var-color-grey-light);
    border-radius: $main-border-radius;

    resize: vertical;

    &::placeholder {
      color: color($var-color-grey);
      font-size: 14px;
    }

    &:focus {
      border-color: color($var-color-grey);

      & ~ .custom-resizer-container {
        border-color: color($var-color-grey);
      }
    }

    &:disabled {
      border-color: color($var-color-grey-light);
    }

    &.error {
      border-color: color($var-color-orange);

      & ~ .custom-resizer-container {
        border-color: color($var-color-orange);
      }
    }

    &::-webkit-resizer {
      display: none;
    }
  }
}
