@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.curtain {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100vw;
  height: 100vh;
}

.event-confirm {
  position: relative;
  z-index: 101;

  width: 280px;
  padding: 30px 10px 20px;

  background-color: color($var-color-white);
  border: 1px solid color($var-color-grey-light);
  border-radius: $main-border-radius;
  box-shadow: 8px 4px 39px 0 rgb(0 0 0 / 15%);

  .title {
    margin-bottom: 10px;

    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.5px;
    text-align: center;
  }

  .question {
    margin-bottom: 25px;

    text-align: center;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;

    .button-item {
      width: 100px;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
