@import "@VIEW/styles/colors";

.collapsible-resource-group-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;

  .title {
    font-weight: 700;
    white-space: break-spaces;
  }

  .collapse-button {
    transform: rotate(-90deg) translateX(-2px);

    pointer-events: all;

    &:hover {
      //

      svg path {
        fill: color($var-color-blue-main);
      }
    }
  }

  &.collapsed-vertical {
    flex-direction: column;
    padding: 0 8px;

    .title {
      font-size: 12px;
      text-align: center;
    }

  }

  &.collapsed-horizontal {
    .collapse-button {
      transform: rotate(90deg) translate(2px, 2px);
    }
  }
}
