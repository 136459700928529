.event-type-option {
  display: flex;
  gap: 8px;
  align-items: center;

  .type-square {
    display: inline-block;
    width: 8px;
    height: 8px;
  }

  white-space: nowrap;
}
