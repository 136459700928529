.dot-spinner {
  $speed: .9s;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  .dot {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    &::before {
      width: 20%;
      height: 20%;

      background-color: var(--color); // from react style
      border-radius: 50%;

      transform: scale(0);
      opacity: 0.5;

      animation: pulse $speed * 1.111 ease-in-out infinite;

      content: '';
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(2)::before {
      animation-delay: $speed * -0.875;
    }

    &:nth-child(3) {
      transform: rotate(90deg);
    }

    &:nth-child(3)::before {
      animation-delay: $speed * -0.75;
    }

    &:nth-child(4) {
      transform: rotate(135deg);
    }

    &:nth-child(4)::before {
      animation-delay: $speed * -0.625;
    }

    &:nth-child(5) {
      transform: rotate(180deg);
    }

    &:nth-child(5)::before {
      animation-delay: $speed * -0.5;
    }

    &:nth-child(6) {
      transform: rotate(225deg);
    }

    &:nth-child(6)::before {
      animation-delay: $speed * -0.375;
    }

    &:nth-child(7) {
      transform: rotate(270deg);
    }

    &:nth-child(7)::before {
      animation-delay: $speed * -0.25;
    }

    &:nth-child(8) {
      transform: rotate(315deg);
    }

    &:nth-child(8)::before {
      animation-delay: $speed * -0.125;
    }
  }

  &.large {
    width: 32px;
    height: 32px;
  }

  &.medium {
    width: 24px;
    height: 24px;
  }

  &.small {
    width: 20px;
    height: 20px;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}
