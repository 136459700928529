.training-event-form {
  width: 380px;

  .training-event-type-wrapper,
  .training-event-sub-type-wrapper,
  .projects-wrapper,
  .location-wrapper,
  .trainer-type-wrapper,
  .trainer-name-wrapper,
  .trainer-email-wrapper,
  .marriott-trainer-wrapper,
  .label-wrapper,
  .description-wrapper,
  .date-range-wrapper,
  .duration-days-wrapper {
    margin-bottom: 8px;
  }

  .unassigned-wrapper,
  .status-confirmed-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  .marriott-trainer-wrapper {
    //

    .dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        flex: 1;
      }

      .dropdown-wrapper {
        //
      }
    }
  }

  .duration-days-wrapper {
    //

    .input-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        flex: 1;
      }

      .input {
        width: 72px;
      }
    }
  }

  .notification-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &.notification-required {
      opacity: 0.6;

      pointer-events: none;
    }
  }
}
