@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.summary-item-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  color: rgb(0 0 0 / 50%);
  font-weight: 700;

  background-color: var(--color);
  border-radius: $main-border-radius;
  cursor: pointer;

  &[data-active="1"],
  &:hover {
    background-color: var(--hover-color) !important;
  }
}
