.event-form-popups {
  //

  .confirm-wrapper {
    position: fixed;

    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
