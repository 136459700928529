@import "@VIEW/styles/colors";
@import "@VIEW/styles/mixins";

.summary-openings-menu-view {
  min-width: 240px;
  height: 100%;

  background-color: color($var-color-dark-soft);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 12px;

    .header-title {
      margin-right: 12px;

      color: color($var-color-white);

      font-weight: 700;
      white-space: nowrap
    }

    .close {
      display: flex;
      align-items: center;

      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }
  }

  .openings-list {
    height: calc(100% - 40px);
    max-height: 346px;
    padding: 0 12px 12px;
    overflow-x: hidden;
    overflow-y: auto;

    @include custom-scrollbar-inverse;

    .opening-item {
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0 6px;

      border-radius: 5px;

      opacity: 0.7;

      .code {
        color: color($var-color-white);
      }

      .unknown {
        color: color($var-color-orange);
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
        margin-left: 6px;

        opacity: 0;
      }

      &:hover {
        background-color: #4E535E;
        opacity: 1;

        .icon-wrapper {
          opacity: 1;
        }
      }

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}
