.event-view {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 20px;

  .pseudo-link {
    display: flex;
    align-items: center;
    height: 100%;

    .link-icon-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: 4px;

      transform: translateY(2px);

      opacity: 0;
    }
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    width: 22px;
    min-width: 22px;
    height: 22px;
    min-height: 22px;

    [data-training-icon="default"] {
      display: flex;
      align-items: center;
    }

    [data-training-icon="link"] {
      display: none;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;
      padding-top: 2px;
      padding-right: 2px;

      border-radius: 5px 5px 5px 2px;
      transform: translateX(1px);

      &:hover {
        background-color: rgb(255 255 255 / 40%);
      }
    }
  }

  .info {
    display: flex;
    margin-left: 7px;

    .title,
    .marsha-code {
      font-weight: 700 !important;

      transition: color 0.2s ease-in-out;
    }

    .title {
      margin-right: 6px;
    }
  }

  &.opening {
    //

    .info {
      align-items: center;
      margin-left: 0;

      .title {
        margin-left: 8px;
      }
    }

    &.centered {
      justify-content: center;
      width: 100%;
      margin: 0 4px;

      .info {
        margin-left: 0;
      }
    }
  }

  &.resource-blocked-period {
    //

    .info .title {
      color: #903C2E;
    }
  }
}
