@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.schedule-menu-tooltip-view {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 12px;

  background-color: color($var-color-dark-soft);
  border-radius: 0 $main-border-radius $main-border-radius $main-border-radius;

  .icon-wrapper {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }

  .date {
    color: color($var-color-white);
    line-height: 14px;
  }
}
