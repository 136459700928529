@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.event-tooltip-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 38px;
  padding: 0 12px;

  background-color: color($var-color-dark-soft);
  border-top-left-radius: $main-border-radius;
  border-top-right-radius: $main-border-radius;
  border-bottom-right-radius: $main-border-radius;

  .title {
    color: color($var-color-white);
    font-size: 12px;
    line-height: 20px;
  }

  .location {
    display: flex;
    align-items: center;
    margin-top: 2px;

    .location-icon-wrapper {
      margin-right: 8px;
      margin-left: -1px;
    }

    .location-name {
      color: color($var-color-white);
      font-size: 12px;

      transform: translateY(-2px);
    }
  }

  .projects {
    margin-top: 4px;
    margin-left: 2px;

    .project-item {
      display: flex;

      .project-icon-wrapper {
        margin-right: 8px;

        transform: translateY(3px);
      }

      .project-name {
        color: color($var-color-grey);
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  &.resource-activity {
    padding: 12px;

    &.title-only {
      padding: 0 12px;
    }
  }

  &.resource-blocked-period {
    padding: 8px 12px;

    .disallow-title {
      margin-top: 8px;

      color: color($var-color-grey);
      font-size: 12px;
      white-space: nowrap;
    }

    .disallow-everyone {
      //
    }

    .disallow-list {
      //

      .disallow-list-item {
        display: flex;

        .disallow-list-icon-wrapper {
          margin-right: 8px;

          transform: translateY(3px);
        }

        .disallow-list-item-title {
          color: color($var-color-grey);
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}
