.summary-openings-column-view {
  width: 100%;
  padding: 0 15px;

  font-weight: 700;
  white-space: break-spaces;

  &[data-summary-openings-view-mode="COLLAPSED"] {
    padding: 0 4px;

    font-size: 12px;
    text-align: center;
  }
}
