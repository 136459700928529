@import "@VIEW/styles/colors";

.event-form-buttons {
  position: sticky;
  bottom: 0;
  z-index: 100;

  display: flex;
  justify-content: flex-end;
  padding: 24px 20px 20px;

  background-color: color($var-color-white);

  .button-wrapper:not(:last-child) {
    margin-right: 16px;
  }

  .save-button-wrapper {
    width: 110px;
  }

  .delete-button-wrapper {
    width: 75px;
  }

  .cancel-button-wrapper {
    width: 75px;
  }
}
