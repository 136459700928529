.select-wrapper {
  z-index: 2;

  background-color: white;
  border-radius: 4px;
}

.selected-resources {
  display: flex;
  justify-content: flex-end;

  .selected-resource-wrapper {
    position: relative;
    z-index: 0;

    display: flex;
    justify-content: flex-end;
    margin-left: -6px;
  }

  .new-resource {
    width: 33px;
    height: 33px;

    background-color: #454A54;
    border-radius: 50%;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      pointer-events: none;
    }
  }
}
