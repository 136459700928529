@import "@VIEW/styles/colors";

.login-view {
  margin-top: 50px;

  .message {
    color: colors($var-color-grey);
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }
}
