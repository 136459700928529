@import "@VIEW/styles/colors";

.main {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header-wrapper {
    background-color: color($var-color-grey-light-3);
  }

  .scheduler-wrapper {
    flex: 1;
  }
}
