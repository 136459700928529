@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.avatar {
  position: relative;

  width: 32px;
  height: 32px;

  overflow: hidden;

  border-radius: 50%;

  .image-logo {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
  }

  .abbreviation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    font-size: 12px;
  }
}

.resource-info-collapsed-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.resource-info-full-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;

  .avatar-wrapper {
    margin-right: 16px;
  }

  .name {
    //
  }
}
