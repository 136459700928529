.scheduler-time-range-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  pointer-events: none;

  .button-wrapper {
    width: 40px;
    height: calc(100% + 2px);

    transform: translateY(-1px);

    pointer-events: all;

    &.prev {
      transform: translateY(-1px) rotate(-180deg);
    }
  }
}
