.menu-wrapper {
  padding: 16px 0 8px;

  input {
    margin-bottom: 10px;
  }

  .menu-input-wrapper {
    padding: 0 16px;
  }

  .menu-list-wrapper {
    padding: 0 13px 0 18px;
  }
}
