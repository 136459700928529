@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.day-number {
  //

  label {
    display: block;

    .input {
      position: relative;

      .icon {
        position: absolute;
        top: 12px;
        left: 16px;

        color: color($var-color-blue-dark);

        svg {
          font-size: 16px;
        }
      }

      input[type="number"] {
        width: 100%;
        height: 40px;
        padding: 0 8px 0 38px;

        font-weight: normal;
        font-size: 14px;
        text-align: center;

        background-color: color($var-color-white);

        border: 1px solid color($var-color-grey-light);
        border-radius: $main-border-radius;

        appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          appearance: none;
        }

        &::placeholder {
          color: color($var-color-grey);
          font-size: 14px;
        }

        &:focus {
          border-color: color($var-color-grey);
        }

        &:disabled {
          border-color: color($var-color-grey-light) !important;
        }

        &.error {
          border-color: color($var-color-orange);
        }
      }
    }
  }
}
