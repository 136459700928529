@import "@VIEW/styles/colors";

.legend {
  display: flex;
  align-items: center;
  height: 100%;

  .item {
    display: flex;
    align-items: center;

    .color-box {
      position: relative;

      width: 12px;
      height: 12px;
      margin-right: 8px;
      overflow: hidden;

      border-radius: 50%;
      transform: rotate(-45deg);

      .top,
      .bottom {
        position: absolute;

        width: 100%;
        height: 100%;
      }

      .top {
        top: -50%;
      }
    }

    .title {
      color: color($var-color-grey);
      font-size: 12px;
      line-height: 20px;

      cursor: default;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
