@import "@VIEW/styles/colors";

.logo-wrapper {
  width: 33px;
  height: 33px;

  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 50%;
  }

  &.logo-error {
    display: flex;
    align-items: center;
    justify-content: center;

    color: color($var-color-dark-soft);

    background-color: color($var-color-grey-light-2);
  }
}
