@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";
@import "@VIEW/styles/mixins";
@import "@bryntum/schedulerpro/schedulerpro.classic-light.min.css";

.custom-resource-column-header,
.custom-resource-column-cell {
  padding-inline: 0 !important;
  border-inline-end: 0 !important;
}

.custom-resource-column-header {
  background-color: color($var-color-white) !important;

  .b-grid-header-text {
    padding: 0;
  }
}

.custom-calendar-entry {
  border-radius: 10px;

  .b-sch-event-content {
    left: 0 !important;

    margin: 0 !important;

    opacity: 1 !important;
  }

  &.calendar-entry-openings {
    //

    .b-sch-event-content {
      width: 100%;
    }
  }

  &.calendar-entry-resource-blocked-period:not(&.unassigned) {
    background: repeating-linear-gradient(-45deg, #FF8C78 1px 8px, #FF9D8C 9px 16px) !important;
  }

  &:hover {
    &.unassigned {
      box-shadow: 0 6px 18px 0 rgb(225 231 243 / 40%);
    }

    &.calendar-entry-travel:not(&.unassigned) {
      box-shadow: 0 6px 18px 0 rgb(108 204 218 / 40%);
    }

    &.calendar-entry-holidays:not(&.unassigned) {
      box-shadow: 0 6px 18px 0 rgb(248 204 70 / 40%);

      &.external-holiday {
        box-shadow: 0 6px 18px 0 rgb(228 176 42 / 40%);
      }
    }

    &.calendar-entry-openings:not(&.unassigned) {
      box-shadow: 0 6px 18px 0 rgb(98 197 79 / 40%);
    }

    &.calendar-entry-resource-activity:not(&.unassigned) {
      box-shadow: 0 6px 18px 0 rgb(62 142 244 / 40%);

      &:not(&.training-event).tentative {
        box-shadow: 0 6px 18px 0 rgb(62 142 244 / 20%);
      }

      &.training-event {
        box-shadow: 0 6px 18px 0 rgb(214 182 255 / 40%);

        &.tentative {
          box-shadow: 0 6px 18px 0 rgb(214 182 255 / 20%);
        }
      }
    }

    &.calendar-entry-resource-blocked-period:not(&.unassigned) {
      box-shadow: 0 6px 18px 0 rgb(255 140 120 / 40%);
    }

    &.training-event {
      //

      * [data-project-link="1"] {
        //

        & > [data-training-icon="default"] {
          display: none;
        }

        & > [data-training-icon="link"] {
          display: flex;
        }
      }
    }

    &.with-url {
      //

      * [data-title="1"] {
        text-decoration: underline;
      }

      * [data-link-icon="1"] {
        opacity: 1;
      }
    }
  }

  &::before,
  &::after {
    top: 50%;

    width: 14px;
    height: 12px;

    background: linear-gradient(to right, rgb(0 0 0 / 50%) 2px, transparent 2px);
    background-size: 4px;
    transform: translateY(-50%);
  }
}

@mixin drag-fade {
  * [data-event-view="1"] {
    filter: brightness(0) opacity(0.4);
  }

  * [data-event-unassigned-view="1"] {
    filter: brightness(0) opacity(0.8);
  }
}

.b-drag-invalid {
  opacity: 1;

  .custom-calendar-entry {
    background-color: rgb(255 143 124 / 60%) !important;
    backdrop-filter: blur(2px);

    @include drag-fade;
  }
}

.b-dragging:not(.b-drag-invalid) {
  opacity: 1;

  .custom-calendar-entry {
    backdrop-filter: blur(2px);

    @include drag-fade;

    &.unassigned-drag-highlight,
    &.unassigned {
      background-color: rgb(225 231 243 / 60%) !important;
    }

    &.calendar-entry-travel:not(&.unassigned-drag-highlight) {
      background-color: rgb(108 204 218 / 60%) !important;
    }

    &.calendar-entry-holidays:not(&.unassigned-drag-highlight) {
      background-color: rgb(248 204 70 / 60%) !important;

      &.external-holiday {
        background-color: rgb(228 176 42 / 60%) !important;
      }
    }

    &.calendar-entry-openings:not(&.unassigned-drag-highlight) {
      background-color: rgb(98 197 79 / 60%) !important;
    }

    &.calendar-entry-resource-activity:not(&.unassigned-drag-highlight) {
      background-color: rgb(62 142 244 / 60%) !important;

      &:not(&.training-event).tentative {
        background-color: rgb(62 142 244 / 30%) !important;
      }

      &.training-event {
        background-color: rgb(214 182 255 / 60%) !important;

        &.tentative {
          background-color: rgb(214 182 255 / 30%) !important;
        }
      }
    }

    &.calendar-entry-resource-blocked-period:not(&.unassigned-drag-highlight) {
      background-color: rgb(255 140 120 / 60%) !important;
    }
  }
}

.custom-event-tooltip {
  background: transparent;
  border-radius: 0 !important;
  box-shadow: none !important;

  .b-panel-body-wrap {
    background-color: transparent;
  }
}

.b-popup.custom-openings-summary-dropdown {
  margin: 2px 0;

  border-radius: $main-border-radius !important;

  .b-menu-body-wrap {
    //

    .b-menu-content {
      overflow: hidden !important;

      .b-widget {
        height: 100%;
        margin: 0;

        background-color: transparent;

        outline: none;
        cursor: default;
        opacity: 1;

        .b-menu-text {
          height: 100%;
        }
      }
    }
  }
}


// global overrides
.b-sch-event-wrap.b-readonly {
  opacity: 1;
}

.b-column-line {
  border-color: color($var-color-grey-light-2);
}

.b-column-line-major {
  // TODO new color
  // border-color: #DEE4EB;
  border-color: transparent;
}

.b-grid-row {
  // TODO new color
  border-bottom: 1px solid #EFF2F5;
}

.b-grid-header-container {
  order: -1;

  // TODO new color
  border-bottom: 1px solid #DEE4EB;
}

// users sidebar
.b-first-visible-child {
  z-index: 2;

  box-shadow: 7px 4px 81px 0 rgb(0 0 0 / 10%);
}

.b-grid-splitter {
  // TODO new color
  background-color: #DEE4EB;
}

// calendar
.b-grid-subgrid.b-last-visible-child {
  background-color: color($var-color-grey-light-3);
}

.b-popup {
  --panel-background-color: transparent;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.b-popup-content {
  background-color: transparent !important;
}

.b-menuitem {
  padding: 0 !important;

  border-top: none;
}

.b-menuitem.b-first-visible-child {
  border-top-left-radius: 0;
  border-top-right-radius: $main-border-radius;
}

.b-menuitem.b-last-visible-child {
  border-bottom-right-radius: $main-border-radius;
  border-bottom-left-radius: $main-border-radius;
}

.b-menuitem span.b-menu-text {
  margin-inline-start: 0;

  background-color: transparent !important;
}

.b-tooltip-content {
  padding: 0;
  padding-block: 0 !important;
  margin-block: 0 !important;

  background: transparent;
}

.b-sch-style-plain.b-sch-custom-color > .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-custom-color > .b-sch-event:not(.b-milestone).b-sch-event-selected, .b-sch-style-plain.b-sch-custom-color > .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
  background-image: none;
}

.b-sch-style-plain.b-sch-custom-color > .b-sch-event.b-milestone:hover .b-sch-event-content::before, .b-sch-style-plain.b-sch-custom-color > .b-sch-event.b-milestone.b-sch-event-selected .b-sch-event-content::before, .b-sch-style-plain.b-sch-custom-color > .b-sch-event.b-milestone.b-sch-event-selected:hover .b-sch-event-content::before {
  background-image: none;
}

.b-grid-headers .b-sch-nonworkingtime {
  background-color: transparent;
}

.b-grid-subgrid .b-sch-range {
  background: #F0F3F6;
  border-right: 1px solid #DEE4EB;
  border-left: 1px solid #DEE4EB;
}

.b-grid-headers {
  .b-sch-dayheadercell-0,
  .b-sch-dayheadercell-6 {
    //
  }
}

.b-sch-timeaxiscolumn {
  height: 80px;
}

.b-sch-header-timeaxis-cell {
  background-color: color($var-color-grey-light-3);
  border-color: #DEE4EB !important;
  border-inline-color: #DEE4EB !important;

  transition: none;
}


.blocked-period-overlap {
  z-index: 2;

  box-sizing: content-box;

  background: #FFAD9F;

  border-top: none !important;
  border-right: none !important;
  border-bottom: 1px solid #FFAD9F;
  border-left: none !important;

  transform: translateY(-1px);

  filter: opacity(70%);
  mix-blend-mode: multiply;

  // filter: opacity(60%);
  // mix-blend-mode: overlay;
}

.b-group-state-icon {
  display: none;
}

.b-group-row {
  margin-top: -1px;

  background-color: rgb(0 0 0 / 2%) !important;
  border-top: 1px solid rgb(0 0 0 / 5%) !important;
  border-bottom: 1px solid rgb(0 0 0 / 5%) !important;

  pointer-events: none;
}

// .b-timeline-subgrid .b-group-row {
//   //
// }

.b-grid-header .b-sch-timerange {
  background-color: transparent;
}

.b-sch-header-text.b-sticky-header {
  font-weight: 700;
  font-size: 16px;
}

.b-sch-current-time {
  //
}

.b-timeline-subgrid .b-sch-line {
  border-left: 2px dotted color($var-color-orange);

  label {
    margin-top: 91px !important;
    margin-left: 1px;
    padding: 8px;

    font-size: 12px;

    background-color: color($var-color-orange);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.b-grid-subgrid .b-sch-range.blocked-period {;

  z-index: 2;

  background: repeating-linear-gradient(-45deg, white 1px 8px, rgb(255 157 140 / 5%) 9px 16px);

  label {
    visibility: hidden;
  }
}

.b-sch-timeranges-canvas {
  z-index: 0;
}

.b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row).b-hover {
  background-color: color($var-color-grey-light-3);
}

.b-grid-row.b-selected {
  background-color: color($var-color-grey-light-3);
}

.b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row).b-hover.b-selected {
  background-color: color($var-color-grey-light-3);
}

.b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell {
  background-color: color($var-color-grey-light-3);
}

.b-virtual-scroller {
  @include custom-scrollbar(color($var-color-grey), transparent, 14px, 20px);

  &:hover {
    @include custom-scrollbar(color($var-color-blue-main), transparent, 14px, 20px);
  }
}

.b-grid-body-container {
  @include custom-scrollbar(color($var-color-grey));

  &:hover {
    @include custom-scrollbar(color($var-color-blue-main));
  }
}

.b-sch-summarybar {
  height: 42px;

  background-color: color($var-color-white);

  .b-timeaxis-tick {
    padding: 0 !important;

    .b-timeaxis-summary-value {
      width: 100%;
      height: 100%;
    }
  }
}

.b-grid-header-container,
.b-grid-footer-container {
  order: -1;
}

.b-grid-footer-container {
  outline: 1px solid #DEE4EB;
}

.b-sch-summarybar:not(.b-sch-vertical) .b-timeaxis-tick:not(:last-child) {
  border-inline-end: 1px solid color($var-color-grey-light-2);
}

.b-grid-row[data-id="group-header-UNASSIGNED_RESOURCE_ROLE"],
.b-grid-row[data-id="group-header-EXTERNAL_TRAINER_RESOURCE_ROLE"] {
  background-color: white !important;

  .b-timeaxis-group-summary {
    background-color: white;

    .b-timeaxis-tick {
      border-bottom: 1px solid #DEE4EB;

      &:not(:last-child) {
        border-right: 1px solid #DEE4EB;
      }

      .b-timeaxis-summary-value {
        height: 100%;
      }
    }
  }

  &.b-grid-group-collapsed {
    //

    .b-timeaxis-group-summary {
      //

      .b-timeaxis-tick {
        border-bottom: 1px solid transparent;
      }
    }
  }
}

.b-grid-row[data-id="group-header-UNASSIGNED_RESOURCE_ROLE"] {
  border: none !important;
}

.b-grid-row[data-id="group-header-EXTERNAL_TRAINER_RESOURCE_ROLE"] {
  border-top: 1px solid #DEE4EB !important;
  border-bottom: none !important;
}

.b-grid-row[data-id="UNASSIGNED_RESOURCE_ID"],
.b-grid-row[data-id="EXTERNAL_TRAINER_RESOURCE_ID"] {
  //

  .b-resourceinfo-cell {
    background-color: white !important;
  }
}
