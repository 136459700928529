@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.cell-tooltip-view {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 8px;

    color: color($var-color-white);
    font-size: 12px;
    line-height: 12px;

    background-color: color($var-color-dark-soft);
    border-top-left-radius: $main-border-radius;
    border-top-right-radius: $main-border-radius;
    border-bottom-right-radius: $main-border-radius;
}
