@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.ghost-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;

  color: color($var-color-blue-light);
  font-weight: 700;
  text-align: center;

  background-color: color($var-color-white);
  border: 1px solid color($var-color-grey-light);
  border-radius: $main-border-radius;

  &:hover {
    color: color($var-color-blue-main);
  }

  &:disabled {
    color: color($var-color-grey);

    background-color: color($var-color-grey-light-2);
    border-color: color($var-color-grey-light-2);

    pointer-events: none;
  }
}
