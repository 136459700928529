@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.blocked-period-tooltip-view {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 16px;

  background-color: color($var-color-dark-soft);
  border-radius: 0 $main-border-radius $main-border-radius $main-border-radius;

  .title {
    color: color($var-color-white);
  }
}
