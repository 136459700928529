@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.basic-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;

  color: color($var-color-white);
  font-weight: 700;
  text-align: center;

  background-color: color($var-color-dark);
  border-radius: $main-border-radius;
  box-shadow: 0 14px 32px 0 #454A5499;

  &:hover {
    background-color: color($var-color-dark-soft);
  }

  &:disabled {
    color: color($var-color-grey);

    background-color: color($var-color-grey-light-2);
    box-shadow: none;

    pointer-events: none;
  }

  .icon-wrapper {
    display: flex;
    margin-right: 5px;
  }
}
