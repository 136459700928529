.resource-wrapper {
  position: relative;

  .remove-button {
    position: absolute;
    top: 0;
    left: 0;

    display: none;
    align-items: center;
    justify-content: center;
    width: 11px;
    height: 11px;

    background-color: #454A54;
    border-radius: 50%;
  }

  &:hover {
    .remove-button {
      display: flex;
    }
  }
}
