@import "@VIEW/styles/colors";

.context-menu-item-view {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 14px;

  background-color: color($var-color-dark-soft);

  .icon-wrapper {
    display: flex;
    align-items: center;
    width: 24px;
    margin-right: 8px;

    opacity: 0.7;
  }

  .title {
    color: color($var-color-white);

    opacity: 0.7;
  }

  &:hover {
    .icon-wrapper {
      opacity: 1;
    }

    .title {
      opacity: 1;
    }
  }
}
