.basic-event-form {
  width: 380px;

  .location-wrapper {
    position: relative;

    margin-bottom: 8px;
  }

  .unassigned-wrapper {
    //
  }

  .assigners-wrapper {
    //

    .dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        flex: 1;
      }
    }
  }

  .unassigned-wrapper,
  .tentative-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  .projects-wrapper,
  .assigners-wrapper,
  .label-wrapper,
  .description-wrapper,
  .date-range-wrapper {
    margin-bottom: 8px;
  }

  .description-wrapper {
    //
  }

  .date-range-wrapper {
    //
  }

  .notification-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
