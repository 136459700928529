.event-delete-confirm-popup {
  width: 270px;
  padding: 20px;

  .icon-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    padding-top: 10px;
  }

  .title {
    margin-bottom: 10px;

    font-weight: 700;
    font-size: 24px;
    line-height: 30px;

    letter-spacing: -1px;
    white-space: pre-line;
    text-align: center;
  }

  .question {
    margin-bottom: 25px;

    text-align: center;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;

    .button-item {
      width: 100px;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
