.loader-wrapper {
    width: 0;
    height: 0;
    position: absolute;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

.loader-inner {
    & svg {
        animation: animationLoader 1000ms linear infinite;
        width: 50px;
        height: 50px;
    }
}


/* Keyframes */

@keyframes animationLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}