@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.search-input {
  position: relative;

  .icon-wrapper {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
  }

  input[type="text"] {
    width: 100%;
    height: 40px;
    padding: 0 16px 0 36px;

    font-weight: normal;
    font-size: 14px;

    background-color: color($var-color-white);

    border: 1px solid color($var-color-grey-light);
    border-radius: $main-border-radius;

    &::placeholder {
      color: color($var-color-grey);
      font-size: 14px;
    }

    &:focus {
      border-color: color($var-color-grey);
    }

    &:disabled {
      border-color: color($var-color-grey-light) !important;
    }

    &.error {
      border-color: color($var-color-orange);
    }
  }

  .clear-button {
    position: absolute;
    top: calc(50% - 6px);
    right: 7px;
    color: color($var-color-blue-dark);
  }
}