@import "colors";
@import "mixins";
@import "react-toastify";

:root {
  @each $key, $value in $app-colors {
    --color-app-#{$key}: #{$value};
  }
}

* {
  @include custom-scrollbar(color($var-color-grey-light));

  &:hover {
    @include custom-scrollbar(color($var-color-blue-main));
  }

  box-sizing: border-box;
  margin: 0;
  padding: 0;

  color: color($var-color-dark-soft);

  font-size: 14px;
  font-family: Arial, sans-serif;

  outline: none;
}

html,
body,
#root {
  height: 100vh;
  overflow: hidden;
}

button {
  cursor: pointer;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

#custom-time-range-controls,
#scheduler-data-loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  height: 39px;

  pointer-events: none;
}

#custom-time-range-controls {
  z-index: 2;
}

#scheduler-data-loading-bar {
  z-index: 2;
}
