.holiday-event-form {
  width: 380px;

  .assigners-wrapper {
    position: relative;
    z-index: 1;

    margin-bottom: 12px;

    .dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        flex: 1;
      }
    }
  }

  .date-range-wrapper {
    position: relative;
    z-index: 0;

    margin-bottom: 8px;
  }
}
