@import "@VIEW/styles/colors";

.event-edit-popup {
  position: relative;

  height: 100%;

  .project-type-wrapper {
    position: sticky;
    top: 0;
    z-index: 100;

    padding: 20px 20px 28px;

    background-color: color($var-color-white);
  }
}
