@import "@VIEW/styles/colors";

.resource-info-header-view {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;

  .title {
    display: flex;
    align-items: center;

    font-weight: 700;
    font-size: 16px
  }

  button {
    width: 24px;
    height: 24px;

    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .icon-wrapper svg path {
      stroke: color($var-color-blue-dark);
    }

    &:hover {
      //

      .icon-wrapper svg path {
        stroke: color($var-color-blue-main);
      }
    }
  }

  &.collapsed {
    justify-content: center;

    button .icon-wrapper {
      transform: rotate(180deg);
    }
  }
}
