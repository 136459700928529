@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  background-color: color($var-color-white);

  border: 1px solid color($var-color-grey-light);
  border-radius: $main-border-radius;

  &:disabled {
    cursor: default;
  }
}
