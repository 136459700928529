@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.date-picker {
  position: relative;

  width: 100%;

  .input {
    display: flex;
    flex-direction: column;

    label {
      display: block;

      .icon {
        position: absolute;
        top: 12px;
        left: 16px;

        color: color($var-color-blue-dark);

        svg {
          font-size: 16px;
        }
      }

      .input-label {
        font-size: 12px;
        line-height: 20px;
      }

      input[type="text"] {
        width: 100%;
        height: 40px;
        padding: 0 16px 0 46px;

        font-weight: normal;
        font-size: 14px;

        background-color: color($var-color-white);

        border: 1px solid color($var-color-grey-light);
        border-radius: $main-border-radius;

        &::placeholder {
          color: color($var-color-grey);
          font-size: 14px;
        }

        &:focus {
          border-color: color($var-color-grey);
        }

        &:disabled {
          border-color: color($var-color-grey-light) !important;
        }

        &.error {
          border-color: color($var-color-orange);
        }
      }
    }
  }

  .calendar {
    padding: 20px;

    border-color: color($var-color-grey-light);
    border-radius: 10px;

    box-shadow: 0 6px 24px 0 #2729371A;

    .header {
      padding-bottom: 20px;

      background-color: color($var-color-white);
      border-bottom: 1px solid rgb(39 41 55 / 12%);

      user-select: none;

      .month-control-icon {
        position: absolute;

        color: color($var-color-dark-2);

        cursor: pointer;

        &-left {
          left: 0;

          transform: rotate(180deg);
        }

        &-right {
          right: 0;
        }
      }

      .current-date {
        color: color($var-color-dark-2);
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
      }
    }

    &.inline {
      padding: 0 3px;

      border-color: transparent;
      border-radius: 0;

      box-shadow: none;
    }
  }

  &.is-open {
    z-index: 100;
  }
}
