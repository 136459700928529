@import "@VIEW/styles/colors";

.custom-error-message {
  display: flex;
  align-items: center;
  min-height: 20px;
  padding: 2px 0;

  color: color($var-color-orange);
  font-size: 12px;
  line-height: 16px;
  white-space: pre-line;
}
