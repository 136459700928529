@import "@VIEW/styles/colors";

.wrapper {
  display: flex;
  align-items: center;
  padding: 12px 14px;

  cursor: pointer;

  & * {

    color: color($var-color-grey);
  }

  &.selected,
  &:hover {
    & * {
      color: #202020;
    }
  }

  .half-selected {
    & ~ div {
      background-color: rgb(78 176 194 / 50%) !important;
      border: none;
    }
  }
}
