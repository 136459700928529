@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.event-time-range-tooltip-view {
  max-width: 240px;
  padding: 12px;
  overflow: hidden;

  background-color: color($var-color-dark-soft);
  border-top-left-radius: $main-border-radius;
  border-top-right-radius: $main-border-radius;
  border-bottom-right-radius: $main-border-radius;

  .icon-wrapper {
    display: flex;
    margin-right: 6px;
  }

  .date-wrapper {
    display: flex;
    align-items: center;

    .date {
      color: color($var-color-white);
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .warning {
    display: flex;
    margin-top: 8px;

    .message {
      color: color($var-color-orange);
    }
  }
}
