@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: color($var-color-white);

  border: 1px solid color($var-color-grey-light);
  border-right: none;
  border-top-left-radius: $main-border-radius;
  border-bottom-left-radius: $main-border-radius;

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    transform: rotate(180deg);
  }
}
